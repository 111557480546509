export function useUniversalPixel() {
    function init() {
        const key = useRuntimeConfig().public.UNIVERSAL_PIXEL_ID;
        if (!key) {
            return;
        }

        useHead({
            script: [
                {
                    children:
                        '!function(e,i){if(!e.pixie){var n=e.pixie=function(e,i,a){n.actionQueue.push({action:e,actionValue:i,params:a})};n.actionQueue=[];var a=i.createElement("script");a.async=!0,a.src="//acdn.adnxs.com/dmp/up/pixie.js";var t=i.getElementsByTagName("head")[0];t.insertBefore(a,t.firstChild)}}(window,document);\n' +
                        `pixie('init', '${key}');`,
                },
                {
                    children: "pixie('event', 'PageView');",
                },
            ],
            noscript: [
                {
                    innerHTML: `<img src="https://ib.adnxs.com/pixie?pi=${key}&e=PageView&script=0" />`,
                },
            ],
        });
    }

    return {
        init,
    };
}
